.paragraphs-item-text-image {
  @include breakpoint($b-mobile) {
    overflow: hidden;
  }

  .field-name-field-image {
    margin: 0 0 20px;

    @include breakpoint($b-mobile) {
      width: calc(50% - 20px);
      max-width: 340px;
    }
  }

  &.left .field-name-field-image {
    @include breakpoint($b-mobile) {
      float: left;
      margin-right: 20px;
    }
  }

  &.right .field-name-field-image {
    @include breakpoint($b-mobile) {
      float: right;
      margin-left: 20px;
    }
  }
}
