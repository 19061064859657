.form-item {
  margin: 0 0 1rem;
  border-radius: 0;

  label {
    display: block;
    margin: 0 0 5px;
    cursor: pointer;

    @include breakpoint($b-mobile) {
      width: 50%;
      float: left;
    }
  }

  .error {
    border: 1px solid $c-red;
  }

  .description {
    margin: 2px 0 0;
    font-size: .9rem;

    @include breakpoint($b-mobile) {
      margin: 2px 0 0 50%;
    }
  }
}

.form-text {
  border: 1px solid $c-black;
  padding: .3rem .8rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: $fw-lighter;

  @include breakpoint($b-mobile) {
    width: 50%;
  }
}

.form-select {
  border: 1px solid $c-black;
  border-radius: 0;
  padding: .3rem .5rem;
  width: 100%;
  font-weight: $fw-lighter;

  @include breakpoint($b-mobile) {
    width: 50%;
  }
}

.form-type-checkbox,
.form-type-radio {
  display: flex;
  gap: 1rem;

  label {
    width: 100%;
    float: none;
  }
}

.form-submit {
  @include button(true);
  border: 0;
  border-radius: 0;
  font-size: 1rem;
  font-weight: $fw-lighter;
}

.form-actions {
  margin: 20px 0 0;

  a {
    margin: 0 0 0 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}

fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;

  legend {
    margin: 0 0 15px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: left;
  }
}
