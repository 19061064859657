.commerce-checkout-progress {
  list-style-type: none;
  text-align: center;

  li {
    float: left;
    padding: 16px 14px 16px 27px;
    background: $c-black;
    color: $c-light-grey;
    position: relative;
    margin: 0 0 10px;

    &::before {
      content: "";
      border-left: 12px solid transparent;
      border-top: 26px solid transparent;
      border-bottom: 26px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "";
      border-left: 12px solid $c-black;
      border-top: 26px solid transparent;
      border-bottom: 26px solid transparent;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 20;
    }

    &.active {
      background: $c-red;
      color: $c-white;

      &::after {
        border-left-color: $c-red;
      }
    }
  }
}
