.field-name-field-status {
  font-weight: $fw-normal;

  .field-item::before {
    display: inline-block;
    margin: 0 10px 0 0;
    font-family: $font-awesome;
  }

  &.in-stock .field-item {
    color: $c-green;

    &::before {
      content: "\f058";
    }
  }

  &.on-demand .field-item {
    color: $c-blue;

    &::before {
      content: "\f059";
    }
  }

  &.reserved .field-item {
    color: $c-orange;

    &::before {
      content: "\f06a";
    }
  }

  &.sold .field-item {
    color: $c-red;

    &::before {
      content: "\f057";
    }
  }
}
