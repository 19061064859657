.field {
  &.field-label-inline {
    .field-label {
      margin-right: 10px;
    }
  }

  .field-label {
    font-weight: $fw-normal;
  }
}
