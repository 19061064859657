.node-product-display {
  &-full {
    .left {
      @include breakpoint($b-mobile) {
        width: calc(40% - 20px);
        margin-right: 20px;
        float: left;
      }

      .commerce-product-field-field-product-images {
        margin: 0 0 10px;

        .field-items {
          overflow: hidden;

          .field-item {
            margin-bottom: 10px;

            &:first-child {
              float: none;
            }

            &:nth-child(n+2) {
              margin-right: 10px;
              float: left;
              max-width: calc((100% - 3 * 10px) / 4)
            }

            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }

      .actions {
        margin: 0 0 10px;

        ul {
          overflow: hidden;

          li {
            float: left;
            margin: 0 10px 10px 0;

            a {
              @include button;
            }
          }
        }
      }
    }

    .right {
      @include breakpoint($b-mobile) {
        width: 60%;
        float: left;
      }
      overflow: hidden;

      .brand-and-social-media {
        @include breakpoint($b-tablet) {
          float: left;
        }

        .commerce-product-field-field-brand,
        .sharethis-buttons {
          margin: 0 20px 20px 0;
        }
      }

      .info {
        float: right;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        width: 100%;
        max-width: 350px;
        margin: 0 0 20px;

        .commerce-product-field-field-promotion {
          float: left;
          margin: 0 0 10px;
          padding: 5px 10px;
          background: $c-red;
          color: $c-white;
          font-size: .8rem;
          line-height: .8rem;
          text-transform: uppercase;
        }

        .price {
          float: right;
          margin: 0 0 0 20px;
          overflow: hidden;

          .commerce-product-field-commerce-price {
            float: left;
            font-weight: $fw-medium;
            font-size: 1.2rem;
            line-height: 1.2rem;

            @include breakpoint($b-tablet) {
              font-size: 1.4rem;
              line-height: 1.4rem;
            }
          }

          .commerce-product-field-commerce-be-vat {
            float: left;
            margin: 0 0 0 10px;
            line-height: 1.2rem;

            @include breakpoint($b-tablet) {
              line-height: 1.4rem;
            }
          }
        }

        .commerce-product-field-field-status {
          width: 100%;
          padding: 5px 10px;
          background: $c-white;
        }

        .field-name-field-product {
          label {
            display: none;
          }

          select {
            width: 100%;
          }

          .form-item-product-id {
            display: none;
          }

          .form-item-quantity {
            float: left;
            width: 25%;
            margin: 0;

            input {
              height: 25px;
              border-color: $c-red;
              font-size: 1rem;
              line-height: 1rem;
              text-align: center;

              @include breakpoint($b-mobile) {
                width: 100%;
              }
            }
          }

          .form-submit {
            display: block;
            width: 75%;
            height: 25px;
            font-size: 1rem;
            line-height: 1rem;
            cursor: pointer;

            &:hover {
              background: $c-black;
              color: $c-white;
            }
          }
        }

        .contact-us,
        .mark-as-sold,
        .mark-as-on-demand {
          a {
            @include button(true);
          }
        }
      }

      .specifications {
        margin: 0 0 20px;
        padding: 20px;
        background: $c-white;

        .commerce-product-field {
          overflow: hidden;

          .field {
            margin: 0 0 3px;
            text-align: left;
            overflow: hidden;

            .field-label {
              float: left;
              width: 50%;
              font-weight: $fw-lighter;
            }

            .field-items {
              float: left;
              width: 50%;

              .field-item {

              }
            }
          }
        }
      }

    }

    .sister-products {
      margin: 0 0 20px;
      padding: 20px 20px 10px;
      background: $c-white;

      ul {
        overflow: hidden;

        li {
          float: left;
          margin: 0 10px 10px 0;
        }
      }

      select {
        margin: 0 0 10px;
      }
    }

    .commerce-product-field-field-product-description {
      clear: both;

      .field-name-field-product-description {
        padding: 20px;
        background: $c-white;
      }
    }
  }

  &.node-product-display-teaser-box {
    a {
      display: block;
      color: $c-black;

      .image-content {
        position: relative;
        padding: 0 0 33px;

        h3 {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          box-sizing: border-box;
          margin: 0;
          padding: 7px 15px;
          background: $c-red;
          color: $c-white;
          font-size: 1rem;
          line-height: 1.4rem;
          text-align: center;
          transition: padding $transition;
        }

        .commerce-product-field-field-product-images {
        }
      }

      &:hover h3 {
        padding-bottom: 17px;
      }

      .info-content {
        padding: 7px 15px;

        .commerce-product-field-commerce-price {

        }

        .commerce-product-field-field-status {

        }
      }
    }
  }
}
