@mixin button($inverse: false) {
  display: inline-block;
  padding: 5px 10px;
  color: $c-white;
  cursor: pointer;

  @if $inverse {
    background: $c-red;
  } @else {
    background: $c-black;
  }

  &:hover,
  &.active {
    @if $inverse {
      background: $c-black;
    } @else {
      background: $c-red;
    }
  }
}
