// General
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: $font;
  font-size: 14px;
  line-height: 20px;
  font-weight: $fw-lighter;
  background: $c-white;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $c-black;
}

a {
  outline: none;
  color: $c-red;
  text-decoration: none;
}

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.clear {
  clear: both;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  text-align: left;
}

h1 {
  display: inline-block;
  margin: 0 0 20px;
  font-size: 2rem;
  font-weight: $fw-lighter;
  line-height: 2rem;
  text-transform: uppercase;
  border-bottom: 3px solid $c-red;
}

h2 {
  margin: 0 0 15px;
  font-size: 1.6rem;
  font-weight: $fw-normal;
  line-height: 2rem;
  color: $c-red;
  text-transform: uppercase;
}

h3 {
  margin: 0 0 10px;
  font-size: 1.2rem;
  font-weight: $fw-normal;
  line-height: 1.6rem;
}

h4 {
  margin: 0 0 10px;
  font-size: 1rem;
  font-weight: $fw-normal;
  line-height: 1.2rem;
}

.row {
  overflow: hidden;
  clear: both;
}

table {
  margin: 0 0 25px;
  font-size: 1rem;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  @include breakpoint($b-mobile) {
    font-size: .9rem;
  }

  @include breakpoint($b-tablet) {
    font-size: 1rem;
  }

  tr {

    &:nth-child(1n) {
      background: $c-light-grey;
    }

    &:nth-child(2n) {
      background: $c-white;
    }
  }

  thead {
    td,
    th {
      background: $c-red;
      font-weight: normal;
      color: $c-white;

      a {
        color: $c-white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  td,
  th {
    padding: 10px 20px;
    border: 1px solid $c-black;
  }

  .subtotal {
    font-weight: bold;
  }
}

// Wrapper.
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.region-content-top {
  margin: 0 0 20px;
  border-bottom: 3px solid $c-black;
  overflow: auto;
}

// Messages.
.messages {
  border: 0;
  padding: 1rem 1.5rem;
  margin: 0 0 1rem;
  color: $c-white;

  &.error {
    background: $c-red;
  }

  &.warning {
    background: $c-orange;
  }

  &.status {
    background: $c-green;
  }

  li {
    list-style: disc;
    margin: 0 0 0 20px;
  }

  a {
    color: $c-black;

    &:hover {
      text-decoration: underline;
    }
  }
}

.node-unpublished {
  background: none;

  &::before {
    content: "This content is unpublished.";
    padding: 10px 13px;
    background: $c-yellow;
    display: block;
    margin: 0 0 20px;
  }
}

// Primary links.
.tabs-wrapper {
  @include max-width-padding;
  overflow: auto;
  text-align: justify;

  ul {
    li {
      float: left;
      margin: 0 10px 10px 0;

      a {
        @include button;
      }
    }
  }
}
