.view-news {
  &.view-display-id-block {
    .view-footer {
      a {
        padding: 7px 15px;
        display: block;
        background: $c-red;
        color: $c-white;
        font-weight: $fw-normal;
        text-align: right;

        &::after {
          content: "»";
          margin: 0 0 0 10px;
          display: inline-block;
        }

        &:hover {
          text-decoration: underline;

          &::after {
            text-decoration: none;
          }
        }
      }
    }
  }
}
