.flex {
  @include flex;

  &.flex-justify {
    justify-content: space-between;
  }

  > div {
    width: 100%;
    margin-bottom: 20px;
  }

  &.flex-2 > div {
    @include flex-2;
  }

  &.flex-2\@mobile > div {
    @include breakpoint($b-mobile) {
      @include flex-2;
    }
  }

  &.flex-2\@tablet > div {
    @include breakpoint($b-tablet) {
      @include flex-2;
    }
  }

  &.flex-2\@desktop > div {
    @include breakpoint($b-desktop) {
      @include flex-2;
    }
  }

  &.flex-3\@mobile > div {
    @include breakpoint($b-mobile) {
      @include flex-3;
    }
  }

  &.flex-3\@tablet > div {
    @include breakpoint($b-tablet) {
      @include flex-3;
    }
  }

  &.flex-3\@desktop > div {
    @include breakpoint($b-desktop) {
      @include flex-3;
    }
  }

  &.flex-4\@tablet > div {
    @include breakpoint($b-tablet) {
      @include flex-4;
    }
  }

  &.flex-4\@desktop > div {
    @include breakpoint($b-desktop) {
      @include flex-4;
    }
  }

  &.flex-5\@desktop > div {
    @include breakpoint($b-desktop) {
      @include flex-5;
    }
  }
}
