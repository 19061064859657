.navigation-wrapper {

  &.mobile {
    left: -400px;
    position: fixed;
    top: 0;
    width: calc(100% - 80px);
    max-width: 300px;
    height: 100%;
    overflow: auto;
    background: $c-black;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .75);
    z-index: 100;
    transition: left .5s;

    @include breakpoint($b-desktop) {
      display: none;
    }

    &.open {
      left: 0;
    }

    nav {
      .menu-block-wrapper {
        > ul {
          > li {
            position: relative;
            border-bottom: 1px solid $c-white;
            overflow: hidden;

            &.expanded {
              > a {
                margin: 0 40px 0 0;
              }

              .caret {
                position: absolute;
                right: 0;
                top: 0;
                width: 20px;
                max-height: 100%;
                margin: 0;
                padding: 10px;
                border-left: 1px solid $c-white;

                &::after {
                  content: "\f0da";
                  display: block;
                  color: $c-white;
                  cursor: pointer;
                  font-family: $font-awesome;
                  font-size: 1.2rem;
                  text-align: center;
                }
              }

              &.open {
                .caret::after {
                  content: "\f0d7";
                }

                > ul {
                  max-height: 600px;
                }
              }
            }

            > a {
              display: block;
              padding: 10px 41px 10px 20px;
              color: $c-white;
              text-transform: uppercase;

              &:hover,
              &.active {
                background: $c-red;
              }
            }

            > ul {
              transition: max-height .5s;
              max-height: 0;

              > li {
                > a {
                  display: block;
                  padding: 10px 40px;
                  border-top: 1px solid $c-white;
                  background: $c-black;
                  color: $c-white;

                  &:hover,
                  &.active {
                    background: $c-red;
                  }
                }
              }
            }
          }
        }
      }
    }

    .block--views---exp-search-page {
      position: relative;
      padding: 20px 0;

      .form-item {
        margin: 0;

        .form-text {
          width: 100%;
          height: 40px;
          border: 0;
          font-size: 1rem;
        }
      }

      .views-submit-button {
        position: absolute;
        top: 20px;
        right: 0;

        &::after {
          content: "\f002";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          color: $c-white;
          cursor: pointer;
          font-family: $font-awesome;
          font-size: 1.2rem;
          line-height: 40px;
          text-align: center;
          text-indent: 0;
          pointer-events: none;
        }

        input {
          height: 40px;
          width: 50px;
          text-indent: -999999px;
        }
      }
    }

    .block-locale {
      float: left;
      padding: 0 20px 20px;

      ul {
        li {
          display: inline-block;
          margin: 0 0 5px;

          a {
            display: block;
            padding: 5px 10px;
            color: $c-white;
            text-transform: uppercase;

            &:hover,
            &.active {
              background: $c-red;
            }
          }
        }
      }
    }

    .social-media {
      float: right;
      padding: 0 20px 20px;

      a {
        margin: 0 10px 0 0;
        font-size: 2rem;
        color: $c-white;

        &:hover {
          color: $c-red;
        }

        &.facebook {
          &:hover {
            color: $c-fb;
          }
        }
      }
    }
  }

  &.desktop {
    background: $c-black;
    display: none;

    @include breakpoint($b-desktop) {
      display: block;
    }

    nav {
      @include max-width-padding(0);
      position: relative;

      .region-menu,
      .cart-search {
        display: inline-block;
      }

      .menu-block-wrapper,
      .cart-search {
        > ul {
          > li {
            position: relative;
            display: inline-block;
            margin-right: -3px;
            border-right: 1px solid $c-white;

            > a {
              display: block;
              padding: 0 20px;
              border-top: 2px solid $c-red;
              color: $c-white;
              line-height: 40px;
              text-transform: uppercase;
              transition: background $transition;

              &:hover,
              &.active,
              &.active-trail {
                background: $c-red;
              }
            }

            > ul {
              position: absolute;
              display: none;
              min-width: 100%;
              z-index: 1;

              &.open {
                display: block;
              }

              > li {
                > a {
                  display: block;
                  padding: 5px 20px;
                  border-top: 1px solid $c-white;
                  background: $c-black;
                  color: $c-white;
                  text-align: center;
                  transition: background $transition;

                  &:hover,
                  &.active {
                    background: $c-red;
                  }
                }
              }
            }
          }
        }
      }

      .cart-search {
        float: right;
        margin-right: 2px;

        > ul {
          > li {
            &:first-child {
              border-left: 1px solid $c-white;
            }

            a {
              font-size: 1.7rem;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}
