.block-facetapi {
  margin: 0 0 10px;
  padding: 10px;
  background: $c-white;

  h2 {
    position: relative;
    margin: 0 0 10px;
    font-size: 1.2rem;
    line-height: 1.2rem;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "\f0d7";
      position: absolute;
      right: 10px;
      top: 2px;
      display: block;
      color: $c-red;
      cursor: pointer;
      font-family: $font-awesome;
      font-size: 1.2rem;
      text-align: center;
    }

    &.open {
      &::after {
        content: "\f0d8";
      }
    }
  }

  > .content {
    display: none;

    @include breakpoint($b-tablet) {
      display: block;
    }

    ul {
      li {
        margin: 0 0 5px;

        a {
          display: inline-block;
          color: $c-black;

          &:hover {
            text-decoration: underline;
          }

          &.facetapi-active {
            display: inline-block;
            margin: 0 10px 0 0;
          }
        }

        ul {
          margin: 5px 0 0 10px;
        }
      }
    }

    .search-api-ranges-widget {
      .range-slider-box {
        .ui-slider-horizontal {
          height: 8px;
          border: 1px solid $c-grey;

          .ui-slider-range {
            background: $c-light-grey;
          }

          .ui-slider-handle {
            top: -4px;
            width: 16px;
            height: 16px;
            border: 0;
            border-radius: 8px;
            margin-left: -8px;
            background: $c-red;
          }
        }
      }

      .range-box {
        overflow: hidden;
        margin: 10px 0 0;

        .range-box-left,
        .range-box-right {
          width: 46%;
          max-width: 100px;

          label {
            display: none;
          }

          .form-text {
            width: 100%;
          }
        }

        .range-box-left {
          float: left;
        }

        .range-box-right {
          float: right;
        }
      }
    }
  }
}
