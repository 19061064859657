.file-video {
  .media-youtube-video {
    position: relative;
    padding-bottom: calc(100% / 16 * 9);
    padding-top: 25px;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
