.banner {
  height: 100px;
  background-size: cover;
  background-position: center center;

  @include breakpoint($b-mobile) {
    height: 150px;
  }

  @include breakpoint($b-tablet) {
    height: 180px;
  }

  @include breakpoint($b-desktop) {
    height: 200px;
  }
}
