@font-face {
  font-family: RobotoRegular;
  src: url("../fonts/Roboto-Light.ttf");
  font-weight: $fw-lighter;
}

@font-face {
  font-family: RobotoRegular;
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: $fw-normal;
}

@font-face {
  font-family: RobotoRegular;
  src: url("../fonts/Roboto-Medium.ttf");
  font-weight: $fw-medium;
}

@font-face {
  font-family: RobotoRegular;
  src: url("../fonts/Roboto-Black.ttf");
  font-weight: $fw-bold;
}
