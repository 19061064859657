@mixin flex($wrap: wrap) {
  display: flex;
  flex-wrap: $wrap;
  overflow: auto;
}

@mixin flex-2 {
  width: calc((100% - 20px) / 2);
  margin-right: 20px;

  &:nth-of-type(2n) {
    margin-right: 0;
  }
}

@mixin flex-3 {
  // Workaround for IE and Edge.
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/6498823
  //width: calc((100% - 2 * 20px) / 3);
  width: calc((100% - 2 * 20px) / 100 * 33.333);

  &,
  &:nth-of-type(2n) {
    margin-right: 20px;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

@mixin flex-4 {
  width: calc((100% - 3 * 20px) / 4);

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n) {
    margin-right: 20px;
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

@mixin flex-5 {
  width: calc((100% - 4 * 20px) / 5);
  margin-right: 20px;

  &,
  &:nth-of-type(2n),
  &:nth-of-type(3n),
  &:nth-of-type(4n) {
    margin-right: 20px;
  }

  &:nth-of-type(5n) {
    margin-right: 0;
  }
}
