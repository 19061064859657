.content-wrapper {
  text-align: justify;
  background: $c-light-grey url("../images/bg.jpg") no-repeat center top;
  background-size: cover;
  clear: both;

  > .content {
    @include max-width-padding;
    overflow: hidden;

    .top-breadcrumb {
      .breadcrumbs {
        overflow: auto;
        margin: 0 0 20px;

        li {
          float: left;
          display: block;

          &::after {
            content: "/";
            margin: 0 10px;
          }

          &:last-child::after {
            content: none;
          }

          a {
            color: $c-black;

            &:hover {
              text-decoration: underline;
            }

            &.active {
              color: $c-red;
            }
          }
        }
      }
    }

    .region-content {
      .sidebar-first & {
        @include breakpoint($b-tablet) {
          float: right;
          width: calc(100% - 200px - 20px);
          margin-left: 20px;
        }
      }
    }

    .region-sidebar-first {
      .sidebar-first & {
        @include breakpoint($b-tablet) {
          float: left;
          width: 200px;
        }
      }
    }

    .field-name-body,
    .field-name-field-body {
      a {
        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        overflow: hidden;

        li {
          list-style-type: circle;
          margin: 0 0 0 20px;
        }
      }

      ol {
        overflow: hidden;

        li {
          list-style-type: decimal;
          margin: 0 0 0 20px;
          padding: 0 0 0 15px;
        }
      }

      p:first-child,
      p:first-child {
        margin-top: 0;
      }
    }

    .pager {
      margin: 20px 0;
      text-align: center;

      li {
        display: inline-block;
        margin-bottom: 3px;

        &.pager-current {
          padding: 10px 20px;
          background: $c-red;
          color: $c-white;
        }

        a {
          display: block;
          padding: 10px 20px;
          background: $c-black;
          color: $c-white;

          &:hover {
            background: $c-red;
          }
        }
      }
    }
  }
}
