.paragraphs-item-spotlight-item {
  a {
    position: relative;
    display: block;

    .field-name-field-title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 7px 15px;
      background: $c-red;
      color: $c-white;
      text-align: center;
      transition: padding $transition;
    }

    &:hover .field-name-field-title {
      padding-bottom: 17px;
    }
  }
}
