.region-content .block--views---exp-search-page {
  margin: 0 0 20px;

  .views-exposed-widget {
    float: left;

    .form-item {
      margin: 0;

      .form-text {
        width: 100%;
        height: 30px;
        border: 0;
        font-size: 1rem;

        &:focus {
          outline: 0;
        }
      }
    }

    .form-submit {
      height: 30px;
      padding: 0 10px;
      background: $c-black;
      font-size: 1rem;
      text-transform: uppercase;

      &:hover {
        color: $c-white;
        cursor: pointer;
      }
    }
  }
}
