.region-search {
  display: none;
  position: absolute;
  right: 20px;
  padding: 10px;
  background: $c-red;
  z-index: 1;

  &.open {
    @include breakpoint($b-desktop) {
      display: block;
    }
  }

  .views-exposed-widget {
    float: left;

    .form-item {
      margin: 0;

      .form-text {
        width: 100%;
        height: 30px;
        border: 0;
        font-size: 1rem;

        &:focus {
          outline: 0;
        }
      }
    }

    .form-submit {
      height: 30px;
      padding: 0 10px;
      background: $c-black;
      font-size: 1rem;
      text-transform: uppercase;

      &:hover {
        color: $c-white;
        cursor: pointer;
      }
    }
  }
}
