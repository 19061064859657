.node-teaser {
  margin: 0 0 10px;
  padding: 10px;
  background: $c-grey;
  overflow: hidden;

  @include breakpoint($b-mobile) {
    padding: 15px;
  }

  .image {
    margin: 0 0 10px;

    @include breakpoint($b-mobile) {
      float: left;
      width: 40%;
      margin: 0 4% 0 0;
    }
  }

  .node-content {
    @include breakpoint($b-mobile) {
      float: left;
      width: 56%;
    }

    .read-more {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
