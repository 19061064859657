#commerce-checkout-form-checkout {
  .cart_contents {
    display: none;
  }
}

.checkout-help {
  margin: 0 0 20px;
}

.line-item-summary {
  clear: both;
  float: right;
}

.commerce-line-item-actions,
.checkout-buttons {
  clear: both;
  float: right;

  input {
    margin: 0 0 0 10px;
  }

  .button-operator {
    display: none;
  }

  .checkout-cancel,
  .checkout-back {
    background: $c-black;
  }
}

.commerce-line-item-views-form .delete-line-item {
  padding: 5px 10px;
  font-size: .8rem;
}
