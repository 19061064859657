.page-online-reservation {
  table {
    td {
      text-align: center;

      &.open {
        background: $c-green;
        color: $c-white;

        a {
          display: block;
          color: $c-white;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.reserved {
        background: $c-red;
      }
    }
  }
}
