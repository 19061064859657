.header-wrapper {
  @include breakpoint($b-desktop) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: $c-white;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .75);
    z-index: 100;
  }

  header {
    @include max-width-padding(5);
    position: relative;
    z-index: 99;

    .header-logo-wrapper {
      width: calc(100% - 100px);
      max-width: 200px;

      @include breakpoint($b-mobile) {
        margin: 0 auto;
      }

      @include breakpoint($b-desktop) {
        position: absolute;
        left: 20px;
        top: 40px;
        padding: 0 20px 20px;
        background: $c-white;
        box-shadow: 0 5px 7px -3px rgba(0, 0, 0, .75);
      }

      .header-logo {
        display: block;
        z-index: 100;

        @include breakpoint($b-desktop) {
          margin-top: -30px;
        }
      }
    }

    .header-top {
      overflow: hidden;

      .region-header-top-left {
        display: none;
        overflow: hidden;
        float: left;
        margin: 0 0 0 260px;

        @include breakpoint($b-desktop) {
          display: block;
        }

        .block-locale {
          margin: 5px 0;

          ul {
            overflow: hidden;

            li {
              float: left;

              &::after {
                content: "-";
                margin: 0 5px;
              }

              &:last-child::after {
                content: "";
              }

              a {
                text-transform: uppercase;
                color: $c-black;
                transition: color $transition;

                &.active,
                &:hover {
                  color: $c-red;
                }
              }
            }
          }
        }
      }

      .region-header-top-right {
        display: none;
        overflow: hidden;
        float: right;
        margin: 0 20px 0 0;

        @include breakpoint($b-desktop) {
          display: block;
        }

        .block-menu-block {
          float: left;
          margin: 5px 0;

          .menu-name-menu-service-menu,
          .menu-name-user-menu,
          .menu-name-menu-user-menu-login {
            ul {
              overflow: hidden;

              li {
                float: left;
                margin: 0 10px 0 0;
                padding: 0 10px 0 0;
                border-right: 1px solid $c-black;

                a {
                  color: $c-black;
                  transition: color $transition;

                  &.active,
                  &:hover {
                    color: $c-red;
                  }
                }
              }
            }
          }

          .menu-name-user-menu,
          .menu-name-menu-user-menu-login {
            ul {
              li:last-child {
                border-right: 0;
              }
            }
          }
        }
      }

      .social-media {
        display: none;
        overflow: hidden;
        float: right;

        @include breakpoint($b-desktop) {
          display: block;
        }

        a {
          float: left;
          margin: 0 10px 0 0;
          font-size: 2rem;

          &:last-child {
            margin: 0;
          }

          &.facebook {
            color: $c-fb;
          }

          &.youtube {
            color: $c-yt;
          }
        }
      }
    }

    .c-hamburger {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 20px;
      padding: 0;
      width: 40px;
      height: 30px;
      font-size: 0;
      text-indent: -9999px;
      appearance: none;
      box-shadow: none;
      border-radius: 0;
      border: 0;
      cursor: pointer;
      transition: background .3s;
      background: none;

      @include breakpoint($b-desktop) {
        display: none;
      }

      &:focus {
        outline: none;
      }

      span {
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        height: 5px;
        background: $c-black;
        transition: background 0s .3s;
        border-radius: 5px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 5px;
          background: $c-black;
          content: "";
          transition-duration: .3s, .3s;
          transition-delay: .3s, 0s;
          border-radius: 5px;
        }

        &::before {
          top: -12px;
          transition-property: top, transform;
        }

        &::after {
          bottom: -12px;
          transition-property: bottom, transform;
        }
      }

      &.open {
        position: fixed;

        span {
          background: none;

          &::before,
          &::after {
            transition-delay: 0s, .3s;
          }

          &::before {
            top: 0;
            transform: rotate(45deg);
          }

          &::after {
            bottom: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .cart {
      display: block;
      position: absolute;
      top: 40px;
      right: 0;
      margin: 20px;
      padding: 0;
      color: $c-black;
      font-size: 3rem;
      transition: color $transition;

      @include breakpoint($b-desktop) {
        display: none;
      }

      &:hover {
        color: $c-red;
      }
    }
  }
}
