.webform-component-checkboxes,
.webform-component-radios {
  > label {
    width: 100%;
    float: none;
    font-weight: bold;
  }
}

.webform-component-select-image {
  $gap: 2rem;

  .form-checkboxes,
  .form-radios {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
  }

  .webform-select-image-legend {
    margin: 0;
    padding: .5rem;
  }

  .form-type-checkbox,
  .form-type-radio {
    margin: 0;
    flex: 0 0 calc((100% - #{$gap}) / 2);
    text-align: left;

    @include breakpoint($b-tablet) {
      flex: 0 0 calc((100% - 3 * #{$gap}) / 4);
    }

    @include breakpoint($b-desktop) {
      flex: 0 0 calc((100% - 5 * #{$gap}) / 6);
    }

    input {
      display: none;
    }

    label {
      display: flex;
      flex-direction: column;
      margin: 0;
      border: 3px solid transparent;
      background: $c-white;

      &:hover,
      &:focus {
        border-color: $c-grey;
      }
    }

    input:checked + label {
      border-color: $c-red;
    }
  }
}
