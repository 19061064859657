.block-facetapi.block--facetapi--emJSUJYbcPVLcCrAEZgbcnZfEWo3j5a2 {
  padding: 0;
  background: transparent;

  > .content {
    display: block;

    ul {
      overflow: auto;

      li {
        margin: 0 5px 5px 0;
        float: left;
        background: $c-black;
        color: $c-white;

        &.active {
          padding: 5px 10px;
          background: $c-red;
        }

        a {
          display: block;
          color: $c-white;

          &.facetapi-active {
            margin: 0 10px 0 0;
          }

          &.facetapi-inactive {
            padding: 5px 10px;

            &:hover {
              background: $c-red;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
