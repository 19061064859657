.node-blog {
  &-full {
    .field-name-field-blog-category {
      overflow: auto;

      .field-items {
        .field-item {
          float: left;
          margin: 0 20px 20px 0;
          padding: 5px 10px;
          background: $c-black;
          color: $c-white;
        }
      }
    }
  }

  &-teaser-box {
    padding: 10px;
    background: $c-grey;

    @include breakpoint($b-mobile) {
      padding: 15px;
    }

    .image {
      margin: 0 0 10px;
    }

    .node-content {
      .read-more {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
