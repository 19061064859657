.footer-wrapper {
  text-align: justify;
  background: $c-grey;

  footer {
    @include max-width-padding;
    overflow: auto;

    .copyright {
      float: left;
      padding: 5px 0 0;
    }

    .region-footer {
      float: left;
      padding: 5px 0 0;

      ul {
        overflow: auto;

        li {
          float: left;

          a {
            margin: 0 0 0 10px;
            padding: 0 0 0 10px;
            border-left: 1px solid $c-black;
            color: $c-black;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .webmaster {
      clear: both;
      float: right;

      @include breakpoint($b-tablet) {
        clear: none;
      }

      a {
        text-indent: -999999px;
        background: url("../images/mullermatthias.png") no-repeat left center;
        width: 77px;
        height: 25px;
        display: block;
      }
    }
  }
}
