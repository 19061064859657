.pinterest-layout {
  column-count: 1;
  column-gap: 10px;

  @include breakpoint($b-mobile) {
    column-count: 2;
  }

  @include breakpoint($b-tablet) {
    column-count: 3;
  }

  @include breakpoint($b-desktop) {
    column-count: 4;
  }

  > div {
    margin: 0 0 10px;
    display: block;

    > div {
      display: inline-block;
    }
  }
}
