#sliding-popup.sliding-popup-bottom {
  padding: 1rem 0;
  border-top: 1px solid $c-grey;
  background-color: $c-black;

  .popup-content #popup-text p {
    font-weight: normal;
  }

  button {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;

    &.agree-button {
      @include button(true);
    }

    &.decline-button {
      @include button;
    }
  }
}
