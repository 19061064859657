.owl-carousel {
  position: relative;
}

.owl-prev,
.owl-next {
  position: absolute;
  display: block;
  top: calc((100% - 3rem) / 2);
  color: $c-white;
  font-size: 3rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .75);
  opacity: .7;

  @include breakpoint($b-mobile) {
    top: calc((100% - 4rem) / 2);
    font-size: 4rem;
  }

  @include breakpoint($b-desktop) {
    top: calc((100% - 5rem) / 2);
    font-size: 5rem;
  }

  &:hover {
    opacity: 1;
  }
}

.owl-prev {
  left: 15px;
}

.owl-next {
  right: 15px;
}

.owl-pagination {
  position: absolute;
  left: 48%;
  bottom: 10px;
}

.owl-page {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #FFF;
  float: left;
  margin: 0 8px 0 0;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .75);

  .active {
    background: #F90;
  }
}

.owl-item {
  > div {
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
