.paragraphs-item-promo {
  position: relative;
  height: 150px;
  background-size: cover;
  background-position: center center;

  @include breakpoint($b-mobile) {
    height: 270px;
  }

  @include breakpoint($b-tablet) {
    height: 350px;
  }

  @include breakpoint($b-desktop) {
    height: 500px;
  }

  a {
    display: block;
    height: 100%;
  }

  .promo-title-wrapper {
    @include max-width-padding(0);
    position: relative;
    height: 100%;

    .promo-title {
      position: absolute;
      top: calc((100% - 1.6rem - 2 * 5px) / 2);
      left: 30px;
      right: 30px;
      margin: 0 10px;
      text-align: center;

      @include breakpoint($b-mobile) {
        top: calc((100% - 2rem - 2 * 10px) / 2);
      }

      @include breakpoint($b-tablet) {
        top: calc((100% - 3rem - 2 * 20px) / 2);
      }

      @include breakpoint($b-desktop) {
        top: calc((100% - 4rem - 2 * 20px) / 2);
        font-size: 4rem;
        line-height: 4rem;
      }

      h2 {
        display: inline-block;
        padding: 5px 10px;
        background: rgba($c-black, .7);
        color: $c-white;
        font-size: 1.6rem;
        line-height: 1.6rem;
        text-align: center;

        @include breakpoint($b-mobile) {
          padding: 10px 20px;
          font-size: 2rem;
          line-height: 2rem;
        }

        @include breakpoint($b-tablet) {
          padding: 20px 30px;
          font-size: 3rem;
          line-height: 3rem;
        }

        @include breakpoint($b-desktop) {
          font-size: 4rem;
          line-height: 4rem;
        }
    }
    }
  }
}
