.paragraphs-item-cta {
  a {
    position: relative;
    display: block;
    border: 3px solid $c-red;

    .field-name-field-title {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 10px 0;
      width: 100%;
      height: 0;
      min-height: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      background: rgba($c-red, .7);
      color: $c-white;
      font-weight: $fw-normal;
      transition: height $transition;
    }

    &:hover .field-name-field-title {
      height: calc(100% - 10px - 10px);
    }
  }
}
